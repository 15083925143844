import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [0,5,6,7,8];

export const dictionary = {
		"/(public)/(home)": [10,[2]],
		"/(public)/(home)/a-place-in-the-ocean": [11,[2]],
		"/(public)/app/(auth)/auth/confirm": [~31,[3]],
		"/app/chatgpt-import/[date]/[id]": [~43,[5]],
		"/app/dashboard": [~44,[5]],
		"/(public)/app/(auth)/forgot-password": [32,[3]],
		"/(public)/app/(auth)/login": [33,[3]],
		"/(public)/app/(auth)/login/mfa": [34,[3]],
		"/(public)/app/(auth)/login/sso": [~35,[3]],
		"/app/notifications": [45,[5]],
		"/(public)/app/(auth)/organization-sign-up/[key]": [~36,[3],[,4]],
		"/app/organization": [~46,[5]],
		"/app/organization/invitation/[key]": [~47,[5]],
		"/app/organization/manage-seats": [~48,[5]],
		"/app/plugins/confluence/select": [~49,[5]],
		"/app/plugins/recent": [~50,[5]],
		"/app/projects": [51,[5]],
		"/app/projects/view/[presentation]": [63,[5]],
		"/app/projects/[projectId]": [~52,[5,6]],
		"/app/projects/[projectId]/diagrams": [~53,[5,6]],
		"/app/projects/[projectId]/diagrams/[documentID]": [54,[5,6]],
		"/app/projects/[projectId]/diagrams/[documentID]/version/v[version].[revision]/view": [56,[5,6,7]],
		"/app/projects/[projectId]/diagrams/[documentID]/version/v[version].[revision]/[mode]": [~55,[5,6,7]],
		"/app/projects/[projectId]/presentations": [~57,[5,6]],
		"/app/projects/[projectId]/presentations/[presentationId]": [58,[5,6,8]],
		"/app/projects/[projectId]/presentations/[presentationId]/edit": [59,[5,6,8]],
		"/app/projects/[projectId]/presentations/[presentationId]/play": [~60,[5,6,8]],
		"/app/projects/[projectId]/presentations/[presentationId]/reorder": [~61,[5,6,8]],
		"/app/projects/[projectId]/select": [~62,[5,6]],
		"/(public)/app/(auth)/reset-password": [37,[3]],
		"/(public)/app/(auth)/sign-up": [38,[3]],
		"/app/teams": [~64,[5]],
		"/app/teams/[teamId]": [~65,[5]],
		"/app/teams/[teamId]/edit": [~66,[5]],
		"/app/trash": [67,[5]],
		"/app/trash/diagrams": [68,[5]],
		"/app/trash/presentations": [69,[5]],
		"/app/user/billing": [~70,[5,9]],
		"/app/user/onboarding": [~71,[5,9]],
		"/app/user/settings": [~72,[5,9]],
		"/(public)/(home)/company": [12,[2]],
		"/(public)/(home)/contact-us": [13,[2]],
		"/(public)/d/[documentID]": [39],
		"/(public)/(home)/faq": [14,[2]],
		"/(public)/(home)/holiday-promo": [15,[2]],
		"/(public)/(home)/landing": [16,[2]],
		"/(public)/maintenance": [40],
		"/(public)/(home)/mermaid-ai": [17,[2]],
		"/(public)/(home)/mermaid-makers": [18,[2]],
		"/(public)/oauth/error": [41],
		"/(public)/play": [~42],
		"/(public)/(home)/plugin-development-opportunity": [19,[2]],
		"/(public)/(home)/plugins": [20,[2]],
		"/(public)/(home)/pricing": [21,[2]],
		"/(public)/(home)/privacy-policy": [22,[2]],
		"/(public)/(home)/product": [23,[2]],
		"/(public)/(home)/rest-api/github/oauth/callback": [24,[2]],
		"/(public)/(home)/solutions": [25,[2]],
		"/(public)/(home)/terms-and-conditions": [26,[2]],
		"/(public)/(home)/terms-of-use": [27,[2]],
		"/(public)/(home)/test2": [~28,[2]],
		"/(public)/(home)/visual-editor": [29,[2]],
		"/(public)/(home)/whiteboard": [30,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';